import React from 'react';
import { Box, Grid, Button, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Page, TextField } from 'src/components/ui';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import './phuket.scss';

const validationSchema = Yup.object().shape({
	firstName: Yup.string().required('First name is required'),
	lastName: Yup.string().required('Last name is required'),
	email: Yup.string().email('Invalid email').required('Email is required'),
	userTypes: Yup.array().min(1, 'Select at least one option'),
});

export const Phuket = () => {
	const { t } = useTranslation();

	const initialValues = {
		firstName: '',
		lastName: '',
		email: '',
		userTypes: [],
	};

	const handleSubmit = (values, { setSubmitting }) => {
		console.log(values);
		setSubmitting(false);
	};

	return (
		<Page title={t('phuket.title')} className="phuket-page">
			<Box className="phuket-container">
				<Typography variant="h2" className="phuket-title">
					{t('phuket.form_title')}
				</Typography>
				<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
					{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
						<Form onSubmit={handleSubmit}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={6} className="text-field-grid">
									<TextField
										error={Boolean(touched.firstName && errors.firstName)}
										fullWidth
										helperText={touched.firstName && errors.firstName}
										variant="filled"
										InputProps={{
											endAdornment: touched.firstName && errors.firstName ? <ErrorIcon /> : null,
										}}
										label={t('phuket.first_name')}
										name="firstName"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.firstName}
										className="matrix-textfield"
									/>
								</Grid>
								<Grid item xs={12} md={6} className="text-field-grid">
									<TextField
										error={Boolean(touched.lastName && errors.lastName)}
										fullWidth
										helperText={touched.lastName && errors.lastName}
										variant="filled"
										InputProps={{
											endAdornment: touched.lastName && errors.lastName ? <ErrorIcon /> : null,
										}}
										label={t('phuket.last_name')}
										name="lastName"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.lastName}
										className="matrix-textfield"
									/>
								</Grid>
								<Grid item xs={12} className="text-field-grid">
									<TextField
										error={Boolean(touched.email && errors.email)}
										fullWidth
										helperText={touched.email && errors.email}
										variant="filled"
										InputProps={{
											endAdornment: touched.email && errors.email ? <ErrorIcon /> : null,
										}}
										label={t('phuket.email')}
										name="email"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.email}
										className="matrix-textfield"
									/>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="subtitle1" className="user-type-label">
										{t('phuket.user_type')}:
									</Typography>
									{['fighter', 'promoter', 'sponsor', 'other'].map((type) => (
										<FormControlLabel
											key={type}
											control={
												<Checkbox
													checked={values.userTypes.includes(type)}
													onChange={handleChange}
													name="userTypes"
													value={type}
												/>
											}
											label={t(`phuket.${type}`)}
										/>
									))}
									{touched.userTypes && errors.userTypes && (
										<Typography color="error" className="error-message">
											{errors.userTypes}
										</Typography>
									)}
								</Grid>
								<Grid item xs={12}>
									<Button type="submit" variant="contained" fullWidth className="submit-button">
										{t('phuket.submit')}
									</Button>
								</Grid>
							</Grid>
						</Form>
					)}
				</Formik>
			</Box>
		</Page>
	);
};
